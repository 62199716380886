<template>

  <section>

    <b-card title="Customer Table">

      <!-- search input -->
      <div>         
        <b-row>
          <b-col cols="6"> 
              <b-button v-b-modal.modal-edit variant="primary" @click="newCustomer">New Customer</b-button>
          </b-col>
          <b-col cols="6">
            <div class="custom-search d-flex justify-content-end">
              <b-form-group>
                <div class="d-flex align-items-center">
                  <label class="mr-1">Search</label>
                  <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" />
                </div>
              </b-form-group>            
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="fields" :rows="customers" ref="table"
        :search-options="{ enabled: true, externalQuery: searchTerm }"
        :pagination-options="{ enabled: true, perPage:pageLength }"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Name -->
          <span v-if="props.column.field === 'id'" class="text-nowrap">
            <a v-b-modal.modal-edit  @click="editCustomer(props.row.id)">{{ props.row.id }}</a>
          </span>
          <span v-else-if="props.column.field === 'name'" class="text-nowrap">
            <a v-b-modal.modal-edit  @click="editCustomer(props.row.id)">{{ props.row.name }}</a>
          </span>
          <span v-else-if="props.column.field === 'address'" class="text-nowrap">
            <a :href="props.row.url" target="_blank">{{ props.row.address }}</a>
          </span>
          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select v-model="pageLength" :options="['10','20','50','100']" class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})" />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength"
                first-number last-number align="right" prev-class="prev-item" next-class="next-item"
                class="mt-1 mb-0" @input="(value)=>props.pageChanged({currentPage:value})">            
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
        </div>
        </template>
      </vue-good-table>

    </b-card>

    <b-modal ref="editmodal"
      id="modal-edit"
      cancel-variant="outline-secondary"
      ok-title="Save"
      cancel-title="Cancel"      
      :title="editedCustomer.title"
      @ok="saveCustomer" :no-close-on-backdrop="true"
    >
      <b-form>
        <b-form-group>
          Name <b-form-input v-model="editedCustomer.name" placeholder="Name"/> 
        </b-form-group>
        <b-form-group>
          Email <b-form-input v-model="editedCustomer.email" placeholder="Email"/>          
        </b-form-group>
        <b-form-group>
          Address <b-form-input v-model="editedCustomer.address" placeholder="Address"/>          
        </b-form-group>
        <b-form-group>
          Comment
          <b-form-textarea rows="5" v-model="editedCustomer.comment" placeholder="Comment"/>    
        </b-form-group>
        <b-form-group>
          Social Media   
          <b-input-group prepend="Facebook" >  <b-form-input v-model="editedCustomer.social.facebook" /> </b-input-group>       
          <b-input-group prepend="Twitter-" >  <b-form-input v-model="editedCustomer.social.twitter"  /> </b-input-group>       
          <b-input-group prepend="Discord-" >  <b-form-input v-model="editedCustomer.social.discord"  /> </b-input-group>       
          <b-input-group prepend="Telegram" >  <b-form-input v-model="editedCustomer.social.telegram" /> </b-input-group>                 
        </b-form-group>        
      </b-form>
    </b-modal>

    <!-- b-table
      :fields="fields_"
      :items="items_"
    >
      <template #cell(name)="data">        
        <b-link :href="`#${data.value.replace(/[^a-z]+/i,'-').toLowerCase()}`">
          {{ data.value }}
        </b-link>
      </template>
    </b-table -->

  </section>

</template>

<script>

import { BIcon, BLink, BCard, BPagination, BForm, BTabs, BTab, BTable, BCardText, BButton, BFormSelect, BCol, BRow, BFormGroup, BInputGroup, BInputGroupPrepend, BFormInput, BFormTextarea } from 'bootstrap-vue'

import { mapActions, mapGetters } from 'vuex'

import Ripple from 'vue-ripple-directive'

import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import ButtonSpinner from '@core/components/button-spinner/ButtonSpinner.vue'

import { VueGoodTable } from 'vue-good-table'

export default {
    data: function() {
        return { 
            editedCustomer: { social: {} },
            pageLength: 10,
            fields: [
                { key: 'id', field: 'id', label: 'Id', formatter: value => `${value}` },
                { key: 'name', field: 'name', label: 'Name' },
                { key: 'email', field: 'email', label: 'Email' },
                { key: 'address' , field: 'address', label: 'Address' },
                { key: 'comment' , field: 'comment', label: 'Comment' },  
                { key: 'social'  , field: 'social', label: 'Social'   },
            ],
            searchTerm: '',
        }
    },
    computed: {
        ...mapGetters("JCCDCustomer",["customers", "isCustomerReady", "customerMap"]),   
    },
    methods: {
        ...mapActions("JCCDCustomer",["loadCustomers", "updateCustomer", "createCustomer"]),
        ...mapActions("logs",["logToast"]),
        async editCustomer(id) {
          console.log(id)
          console.log(this.customerMap)
          this.editedCustomer = { ...this.customerMap[id], mode : 'edit', title: 'Edit Customer' }; 
        },
        async newCustomer() {
            this.editedCustomer = {
                mode: 'new' ,
                name: '',
                email: '',
                address: '',
                comment: '',
                social: { facebook: '', twitter: '', discord: '', telegram: '' },
                title: 'New Customer'
            }            
        },
        async saveCustomer(bvModalEvt) {            
            bvModalEvt.preventDefault();            
            if (this.editedCustomer.name === "") {
                bvModalEvt.preventDefault();
                this.showToast("error","Name is Empty");
                return;
            }
            if (this.editedCustomer.address === "") {
                bvModalEvt.preventDefault();
                this.showToast("error","Address is Empty");
                return;
            }
            if (this.editedCustomer.mode == 'edit') {
                await this.updateCustomer(this.editedCustomer).catch((error)=>{
                    if( error.response ){
                        console.log(error.response.data); // => the response payload 
                        this.showToast("danger","Caanot create customer", error.response.data.toString());
                    } else {
                        console.log(error)                                               
                        this.showToast("danger","Cannot create customer", error.toString());
                    }
                }).then((response)=>{ this.$refs.editmodal.hide() })
            } else {
                await this.createCustomer(this.editedCustomer).catch((error)=>{
                    if( error.response ){
                        console.log(error.response.data); // => the response payload 
                        this.showToast("danger","Caanot create customer", error.response.data.toString());
                    } else {
                        console.log(error)                                               
                        this.showToast("danger","Cannot create customer", error.toString());
                    }
                }).then((response)=>{ this.$refs.editmodal.hide() })
            }
        },
        showToast: function(variant, title, message) {
            this.logToast(variant, title, message)
            this.$toast({
                component: ToastificationContent,
                props: { title: title, text: message, variant },
            })
        },       
    },
    mounted: function() {
        this.loadCustomers()
    },
    components: {
        BCardText,
        BCardActions,
        BCard, BPagination, BIcon,
        BLink, BTable, BCol, BRow, BTabs, BTab, BButton, BFormSelect, BFormGroup, BFormInput, BInputGroup, BFormTextarea, BForm,
        VueGoodTable,
        BInputGroupPrepend,
        ButtonSpinner,
        ToastificationContent,
    },
    directives: {
        Ripple,
    },    
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>